import { useMemo } from "react";
import {
  HardHatIcon,
  MapPinnedIcon,
  TriangleAlertIcon,
  VideoIcon,
} from "lucide-react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { formatBRLNumber } from "@/utils/formatBRLNumber";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { DateConverter } from "@/utils/DateConverter";

interface VehicleDetailsCardProps {
  videos: VideoInputOutputDTO[];
};

export default function VehicleDetailsCard({ videos }: VehicleDetailsCardProps) {
  const totalRecordedHours = useMemo(() => (
    Math.ceil(videos.reduce((acc, { duracaoDoVideo }) => (
      acc + DateConverter.ConvertTimeToHourNumber(duracaoDoVideo, false)
    ), 0))
  ), [videos]);

  const totalServiceHours = useMemo(() => (
    Math.ceil(videos.reduce((acc, { horasEmServico }) => (
      acc + DateConverter.ConvertTimeToHourNumber(horasEmServico, false)
    ), 0))
  ), [videos]);

  const totalTravelHours = useMemo(() => (
    Math.ceil(videos.reduce((acc, { horasEmDeslocamento = '00:00:00' }) => (
      acc + DateConverter.ConvertTimeToHourNumber(horasEmDeslocamento, false)
    ), 0))
  ), [videos]);

  const alertCount = useMemo(() => (
    videos.reduce((acc, { quantidadeDeAlerta = 0 }) => acc + quantidadeDeAlerta, 0)
  ), [videos]);

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4 mb-6">
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Horas de gravação</CardTitle>
          <VideoIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(totalRecordedHours)} h</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Horas em serviço</CardTitle>
          <HardHatIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(totalServiceHours)} h</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Horas em deslocamento</CardTitle>
          <MapPinnedIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(totalTravelHours)} h</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Quantidade de alertas</CardTitle>
          <TriangleAlertIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(alertCount)}</p>
        </CardContent>
      </Card>
    </div>
  );
};
