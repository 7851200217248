import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";

import { useFilterContext } from "../../contexts/FilterContext";
import { BaseDTO } from "../../models/BaseDTO";

interface SelectBaseProps {
  bases: BaseDTO[];
};

export default function SelectBase({ bases }: SelectBaseProps) {
  const { base, setBase } = useFilterContext();

  return (
    <Select value={base} onValueChange={(value) => setBase(value)}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Selecione a base" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="all">Todas as bases</SelectItem>
          <SelectLabel className="mt-4">Bases</SelectLabel>
          {bases.map((b: BaseDTO) => (
            <SelectItem
              key={b.id}
              value={b.chave.toLocaleLowerCase()}
            >
              {b.nome}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
