import { Badge } from "@/components/ui/Badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { slugfy } from "@/lib/utils";
import { VehiclesNoCommunicationType } from "@/models/VehiclesNoCommunicationType";

interface TableDataProps {
  tableData: VehiclesNoCommunicationType[];
};

export default function DashboardHomeTable({ tableData }: TableDataProps) {
  return (
    <Card className="xl:col-span-2" x-chunk="dashboard-01-chunk-4">
      <CardHeader>
        <CardTitle>Veículos sem comunicação</CardTitle>
        <CardDescription>
          mais de 3 dias sem comunicação com a base
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ScrollArea className="w-full h-[252px]" type="hover">
          <Table>
            <TableHeader className="sticky top-0 bg-white hover:bg-white">
              <TableRow>
                <TableHead>Base</TableHead>
                <TableHead className="text-center">Veículo (Placa)</TableHead>
                <TableHead className="text-center">
                  Identificador interno
                </TableHead>
                <TableHead className="text-center">
                  Dias sem comunicação
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={`${row.veiculoId}-${slugfy(row.localidadeBaseNome)}`}
                >
                  <TableCell>{row.localidadeBaseNome}</TableCell>
                  <TableCell className="text-center">
                    {row.veiculoPlaca}
                  </TableCell>
                  <TableCell className="text-center">
                    {row.veiculoIdentificadorInterno}
                  </TableCell>
                  <TableCell className="text-center">
                    <Badge className="text-xs" variant="default">
                      {row.diasSemComunicacao}
                    </Badge>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};
