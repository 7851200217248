import { useQuery } from 'react-query';

import { http } from '../../useAxios';
import { VehiclesNoCommunicationType } from '../../../models/VehiclesNoCommunicationType';

export async function getNoCommunication(filter: string): Promise<VehiclesNoCommunicationType[]> {
  try {
    const { data: vehiclesByMonth } = await http.get<VehiclesNoCommunicationType[]>(
      `/Videos/ListarVeiculosSemComunicacao?filter=${filter}`
    );

    return vehiclesByMonth;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterVehiclesNoCommunication(filter?: string) {
  const ft = filter || 'all';

  return useQuery({
    queryKey: [`vehicles-without-communication-${ft}`],
    queryFn: () => getNoCommunication(ft),
    staleTime: 1000 * 60 * 60,
    enabled: true,
  });
};
