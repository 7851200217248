import axios from "axios";

import { authStore } from "../store/useAuth/useAuth";
import { ROUTES_PATH } from "../routes/routesPath";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000,
});

instance.interceptors.request.use(
  config => {
    const token = authStore.getState().token;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => {
    return response;
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      setTimeout(() => {
        window.location.pathname = ROUTES_PATH.dashboard;
      }, 3000);
    }

    return Promise.reject(error);
  },
);

export { instance as http };
